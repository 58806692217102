const Creator = Go.vue;

export default Creator;

Creator.data = function() {
  return {
    loading: true,
  };
};

Creator.beforeMount = function() {
  if (!this.populate) {
    this.loading = false;
  }

  if (this.populate) {
    this.getUserInfo();
  }
};

Creator.methods.getUserInfo = async function() {
  try {
    this.req = await this.api.get(`creator/getInfo?q=${this.creator._id}`);
    Object.assign(this.creator, this.req.data);
  } catch (error) {
    this.alert(Go.getErrorMessage(error));
  }
  this.loading = false;
};
