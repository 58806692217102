<template>
  <div class="CreatorExploreInfoContent" :style="`--image:url(${this.creator.cover});--zoom:${cardStyle.zoom};--top:${cardStyle.top};`">
    <span class="overblur"></span>
    <Spacer num="15" />
    <div class="Statistics">
      <div class="StatisticsContent" @click="modal.close(() => router(`/${creator.user}`))">
        <LargeButton :UserAvatar="creator" :label="`<strong>@${creator.user}</trong>`" />

        <div mauto dpadding tcenter v-if="loading">
          <Icon name="gspinner" v-size="`200%`" />
        </div>

        <div class="CreatorExploreOptions" v-else>
          <LargeButton icon="blog" :label="`<strong>Posts</trong>`" :rightDesc="`<strong>${Go.number_format(creator.totalPosts)}</strong>`" />
          <LargeButton icon="heart" :label="`<strong>Likes</trong>`" :rightDesc="`<strong>${Go.number_format(creator.totalLikes)}</strong>`" />
          <LargeButton
            icon="mf_lock"
            :label="`<strong>Drive</trong>`"
            :rightDesc="
              `<div class='driveTags'>
              <go-tag>${$locale['images']} <strong p11>${Go.number_format(creator.totalImages)}</strong></go-tag>
              <go-tag>${$locale['videos']} <strong p22>${Go.number_format(creator.totalVideos)}</strong></go-tag>
            </div>`
            "
          />
        </div>
      </div>
      <Spacer num="1" />
      <div class="StatisticsContent">
        <LargeButton icon="play" :label="$locale['watch']" @click="modal.close(() => router(`/watch/?creator=${creator.user}`))" />
      </div>
    </div>
  </div>
</template>

<script>
import CreatorInfo from "./CreatorInfo.js";
export default {
  mixins: [CreatorInfo],
  props: ["creator", "modal", "populate"],
  computed: {
    videoCover: function() {
      return this.creator.videoCover && this.creator.videoCover.src && this.creator.videoCover;
    },
    cardStyle: function() {
      const style = this.creator.style && this.creator.style.cover ? this.creator.style.cover : { zoom: "cover", top: "50%" };
      return style;
    },
  },
};
</script>

<style lang="scss">
.CreatorExploreInfo {
  .CreatorExploreInfoContent {
    position: relative;

    &::before,
    &::after {
      @include Absolute();
      content: "";
      width: 100%;
      height: 100%;
      @include BgImage(var(--image));
      z-index: -1;
    }

    &:after {
      background-size: var(--zoom);
      background-position: top calc(0px + var(--top)) center;
      background-color: #000;
      pointer-events: none;
      z-index: 1;
    }

    .overblur {
      @include Absolute();
      width: 100%;
      height: 100%;
      backdrop-filter: blur(20px);
    }

    .Statistics {
      padding: $mpadding * 1.5;
      position: relative;
      z-index: 2;
    }

    .StatisticsContent {
      @include Glass(#000, 0.2);
      color: #fff;
      overflow: hidden;
      border-radius: $mpadding * 1.5;
    }

    .StatisticsContent .LargeButton:last-child .line {
      display: none;
    }

    .driveTags {
      display: flex;
      align-items: center;
      gap: $mpadding/2;
      margin: 0 0 0 $mpadding;
    }

    .driveTags go-tag {
      @include GlassForce(#fff, 0.2);
      display: flex !important;
      align-items: center;
      gap: $mpadding/2;
    }
  }
}
</style>
